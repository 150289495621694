@media all and (min-width: 480px) {
  .profile-div {
    padding: 60px 0;
  }

  .profile-div form {
    margin: 0 auto;
    max-width: 650px;
  }
}

.profile-div form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.alert-div {
  margin: 0 auto;
  max-width: 650px;
}
