@media all and (min-width: 480px) {
  .change-email-div {
    padding: 60px 0;
  }

  .change-email-div form {
    margin: 0 auto;
    max-width: 650px;
  }
}

.alert-div {
  margin: 0 auto;
  max-width: 650px;
}
