.nav-bar {
  background-color: #36578d;
}

.nav-div {
  color: #fff;
  text-decoration: none;
}

.nav-link {
  color: #fff;
  text-decoration: none;
}

.navbar-navbar-nav > a:hover {
  background-color: gray;
}

.inactive {
  color: #fff;
  text-decoration: none;
}

.active {
  color: red;
  text-decoration: none;
}
